import React from 'react';
import Layout from '../components/layout/Layout';
import CustomersNew from '../components/customers/New';

export default (props) => {
  return (
    <Layout page="signups">
      <CustomersNew />
    </Layout>
  );
};
